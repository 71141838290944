button,
input[type='submit'],
input[type='button'],
input[type='checkbox'] {
  cursor: pointer;
}

input:not([type='checkbox']):not([type='radio']),
select {
  display: block;
}

input,
select,
button,
textarea {
  color: var(--form-text);
  background-color: var(--background);

  font-family: inherit;
  font-size: inherit;

  margin-right: 6px;
  margin-bottom: 6px;
  padding: 10px;

  border: none;
  border-radius: 6px;
  outline: none;
}

input:not([type='checkbox']):not([type='radio']),
select,
button,
textarea {
  -webkit-appearance: none;
}

textarea {
  margin-right: 0;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}

button,
input[type='submit'],
input[type='button'] {
  padding-right: 30px;
  padding-left: 30px;
}

button:hover,
input[type='submit']:hover,
input[type='button']:hover {
  background: var(--button-hover);
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  box-shadow: 0 0 0 2px var(--focus);
}

input[type='checkbox']:active,
input[type='radio']:active,
input[type='submit']:active,
input[type='button']:active,
button:active {
  transform: translateY(2px);
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

::placeholder {
  color: var(--form-placeholder);
}
