img {
  max-width: 100%;
  height: auto;
}

hr {
  border: none;
  border-top: 1px solid var(--border);
}

table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

td,
th {
  padding: 6px;
  text-align: left;
}

th {
  border-bottom: 1px solid var(--border);
}

tbody tr:nth-child(even) {
  background-color: var(--background-alt);
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb);
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-thumb-hover);
}

::selection {
  background-color: var(--selection);
}
