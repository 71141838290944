body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.4;

  max-width: 800px;
  margin: 20px auto;
  padding: 0 10px;

  color: var(--text-main);
  background: var(--background-body);

  text-rendering: optimizeLegibility;
}

button, input, textarea {
  transition: background-color var(--animation-duration) linear,
              border-color var(--animation-duration) linear,
              color var(--animation-duration) linear,
              box-shadow var(--animation-duration) linear,
              transform var(--animation-duration) ease;
}
